import { Column } from '@xo-union/tk-component-grid';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import React from 'react';
import { connect } from 'react-redux';
import { State } from 'types/redux';
import InlineRfqModal from '../../../../../../../pages/Storefront/containers/InlineRFQ/components/InlineRfqModal/InlineRfqModal';
import { useInlineRfqForm } from '../../../../../../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import RFQButton from '../../../../../../../pages/Storefront/containers/cta_box/RfqButton';
import FavoritesButton from '../../../../../../../pages/Storefront/containers/vendor_info/components/favorites_button';
import { reviewsLightboxModalTypeAtom } from '../../../atoms';
import Styles from './footer.scss';

type StateProps = ReturnType<typeof mapStateToProps>;
type FooterProps = StateProps;

const Footer = ({ membership, vendor }: FooterProps) => {
	const modalType = useAtomValue(reviewsLightboxModalTypeAtom);
	const sourceContent =
		modalType === 'overview' ? 'review overview' : 'review gallery';
	const formContext = useInlineRfqForm({ initiator: sourceContent });

	return (
		<>
			<InlineRfqModal context={formContext} />
			<div className={Styles.footer} role="presentation">
				<div className={classnames(Styles.buttonsContainer)}>
					<Column xs="6" className={Styles.buttonColumns}>
						<FavoritesButton
							expanded
							membership={membership}
							vendor={vendor}
							className={Styles.favButton}
							sourceContent={sourceContent}
						/>
					</Column>
					<Column xs="6" className={Styles.buttonColumns}>
						<RFQButton
							size="md"
							handleCTAClick={formContext.openModal}
							styleName={Styles.rfqButton}
						/>
					</Column>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	membership: state.membership,
	vendor: state.vendor.vendor as Vendor.Decorated,
});

export default connect<StateProps, {}, {}>(mapStateToProps)(Footer);
