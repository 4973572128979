import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { DisplayLink } from '@xo-union/tk-ui-links';
import { Body1 } from '@xo-union/tk-ui-typography';
import cx from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { Decorated } from 'types/vendor';
import VendorAnalytics from '../../decorators/vendorAnalytics';
import { usePdfUrl } from './hooks/use-pdf-url';
import Styles from './pdf.scss';

const DocumentIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>document</title>
		<rect width="24" height="24" fill="white" />
		<path
			d="M4 1V23H19.75V6.86667L13.75 1H4Z"
			strokeWidth="1.3"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.75 1V6.86667H19.75"
			strokeWidth="1.3"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.75 8.33334H10.75M7.75 12H16M7.75 15.6667H16M7.75 19.3333H14.5"
			strokeWidth="1.3"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

const defaultText = 'Download our digital pricing PDF:';

interface PricingPdfProps {
	text?: string;
	children?: React.ReactNode;
	className?: string;
}

export const PricingPdf = ({
	text = defaultText,
	children,
	className,
}: PricingPdfProps) => {
	const { track } = useAnalyticsContext();
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const vendorAnalytics = useMemo(() => new VendorAnalytics(vendor), [vendor]);
	const pdfUrl = usePdfUrl();

	const onClickHandler = useCallback(() => {
		track({
			event: 'View Pricing PDF',
			properties: { ...vendorAnalytics.baseEventProps() },
		});
	}, [track, vendorAnalytics.baseEventProps]);

	if (!pdfUrl) {
		return null;
	}

	return (
		<div className={cx(Styles.container, { [className || '']: className })}>
			<div className={Styles.wrapper}>
				<Body1>{text}</Body1>
				<div>
					<DisplayLink
						color="primary"
						href={pdfUrl}
						onClick={onClickHandler}
						size="md"
						rel="nofollow noreferrer"
						target="_blank"
						className={Styles.link}
					>
						<DocumentIcon />
						<Body1>{`${vendor.name} PDF`}</Body1>
					</DisplayLink>
				</div>
			</div>
			{children}
		</div>
	);
};
