import { useScreenDetails } from '@hooks/useScreenDetails';
import { Img, Picture } from '@xo-union/tk-component-picture';
import React, { useMemo } from 'react';
import { Gallery } from 'types/reviews';
import { usePictureWrapper } from '../../hooks/use-picture-wrapper';

export interface WrapperProps {
	item: Gallery;
	optionalCssClass?: string;
	height?: number;
	width?: number;
}

const ReviewsImageWrapper = ({
	height,
	item,
	optionalCssClass = '',
	width,
}: WrapperProps) => {
	const { idToUse, imageComplete, onError, onLoad } = usePictureWrapper(
		item.sourceId,
	);
	const [isLandscape] = useScreenDetails();

	const aspectRatio = useMemo(() => {
		if (isLandscape) {
			return window.innerWidth / window.innerHeight;
		}
		if (width && height) {
			return width / height;
		}
		return 'initial';
	}, [isLandscape, width, height, window.innerWidth, window.innerHeight]);

	return (
		<Picture id={idToUse}>
			<Img
				width={width}
				height={height}
				className={isLandscape ? optionalCssClass : ''}
				alt="User review media"
				elementProps={{
					onError,
					onLoad,
					ref: imageComplete,
					style: { aspectRatio },
				}}
				smartCrop
				fit
			/>
		</Picture>
	);
};

export { ReviewsImageWrapper };
