import BlankButton from '@xo-union/component-blank-button';
import { compose } from '@xo-union/react-css-modules';
import { Button } from '@xo-union/tk-component-buttons';
import { Field } from '@xo-union/tk-component-fields';
import { FormColumn, FormRow } from '@xo-union/tk-component-form-grid';
import Icon from '@xo-union/tk-component-icons';
import { useAtom } from 'jotai';
import React, { ChangeEvent, KeyboardEvent } from 'react';
import { searchTextDisplayAtom } from '../atoms';
import Styles from './styles.scss';

const classes = compose({
	input: Styles.input,
	label: Styles.label,
});

interface Props {
	handleClear: () => void;
	setSearchText: (content: string | undefined) => void;
}

const SearchBar = ({ handleClear, setSearchText }: Props) => {
	const [search, setSearch] = useAtom(searchTextDisplayAtom);
	const handleClick = () => setSearchText(search);
	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) =>
		setSearch(e.target.value);

	const handleEnter = (e: KeyboardEvent) => {
		if (e.key === 'Enter') {
			handleClick();
		}
	};

	return (
		<FormRow className={Styles.formRow}>
			<FormColumn className={Styles.formColumn}>
				<div className={Styles.buttonGroup}>
					<Field
						classes={classes}
						label="Search reviews"
						name="Search reviews"
						value={search}
						onChange={handleOnChange}
						onKeyDown={handleEnter}
					/>
					{search.length > 0 && (
						<BlankButton
							className={Styles.iconContainer}
							onClick={handleClear}
							data-testid="clear-button"
						>
							<Icon size="md" name="close_circle" className={Styles.icon} />
						</BlankButton>
					)}
				</div>
				<Button
					className={Styles.button}
					aria-label="search"
					color="tertiary"
					onClick={handleClick}
					type="button"
				>
					<Icon
						size="md"
						name="search"
						aria-hidden="true"
						className={Styles.searchIcon}
					/>
				</Button>
			</FormColumn>
		</FormRow>
	);
};

export { SearchBar };
