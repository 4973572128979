import { useAtomValue } from 'jotai';
import React, { FC } from 'react';
import { reviewsLightboxModalTypeAtom } from '../../../atoms';
import { useGalleryReview } from '../../../hooks/use-gallery-review';
import { ReviewContentWrapper } from '../../review-content-wrapper';
import Footer from '../portrait/footer';
import { Header } from './header';
import Styles from './styles.scss';

export interface LandscapeProps {
	vendorName: Vendor.Decorated['name'];
}

const Landscape: FC<LandscapeProps> = (props) => {
	const { children, vendorName } = props;
	const reviewsLightboxModalType = useAtomValue(reviewsLightboxModalTypeAtom);
	const review = useGalleryReview();
	return (
		<div className={Styles.container}>
			<Header vendorName={vendorName} />
			{children}
			{reviewsLightboxModalType === 'details' && (
				<ReviewContentWrapper review={review} />
			)}
			{reviewsLightboxModalType === 'overview' && <Footer />}
		</div>
	);
};

export { Landscape };
