import { AvailabilityResponse } from '@components/calendar/types';
import {
	CALENDAR_RANGE_YEARS,
	fetchAvailability,
} from '@components/calendar/utils';
import { addMonths, addYears, startOfMonth } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

export const useFetchAvailability = (
	vendorId: string,
	shouldShowAvailability: boolean,
) => {
	const [availabilityData, setAvailabilityData] =
		useState<AvailabilityResponse | null>(null);

	const fetchAvailabilityMemo = useCallback(fetchAvailability, []);

	const handleAvailabilityResponse = useCallback(async (response: Response) => {
		if (response.status === 200) {
			setAvailabilityData(await response.json());
		} else if (response.status === 204) {
			throw new Error('Vendor has not configured their availability.');
		} else {
			throw new Error(
				`Fetching availability failed with status code: ${response.status}`,
			);
		}
	}, []);

	const fetchInitialAvailability = useCallback(async () => {
		try {
			const response = await fetchAvailabilityMemo({
				storefrontId: vendorId,
				startDate: new Date(),
				endDate: startOfMonth(addMonths(new Date(), 1)),
			});
			await handleAvailabilityResponse(response);

			if (response.status === 200) {
				const fullResponse = await fetchAvailabilityMemo({
					storefrontId: vendorId,
					startDate: new Date(),
					endDate: addYears(new Date(), CALENDAR_RANGE_YEARS),
				});
				await handleAvailabilityResponse(fullResponse);
			}
		} catch (error) {
			console.error(`Fetching availability failed: ${error}`);
		}
	}, [fetchAvailabilityMemo, handleAvailabilityResponse, vendorId]);

	useEffect(() => {
		if (shouldShowAvailability) {
			fetchInitialAvailability();
		}
	}, [fetchInitialAvailability, shouldShowAvailability]);

	return availabilityData;
};
