import {
	GetReviewsForStorefrontQueryParams,
	REVIEWS_FOR_PAGE_1,
	REVIEWS_PER_PAGE,
	getReviewsForStorefront,
	pageAfterFirst,
} from '@api/reviews';
import { escapeRegExp } from '@utils/regex';
import { atom } from 'jotai';
import { loadable, selectAtom } from 'jotai/utils';
import { ReviewsResponseData } from 'types/api/reviews';
import {
	GetTextParams,
	QueryParamAction,
	SSFState,
	SearchSortFilterReducerState,
} from './types';

export const initialState: SSFState = {
	searchTextDisplay: '',
	searchText: undefined,
	reviews: [],
};

export const queryParamInitialState: GetReviewsForStorefrontQueryParams = {
	content: undefined,
	page: 1,
	stars: undefined,
	sort: 'selectiontype:asc|createddate:desc',
};

export const searchSortFilterInitialState: SearchSortFilterReducerState = {
	queryParams: queryParamInitialState,
	showText: 'hide',
	lastAction: 'ssr-hydration',
};

export const getText = ({ actionType, queryParams }: GetTextParams) => {
	switch (actionType) {
		case 'search':
			return queryParams.stars && queryParams.stars.length > 0
				? 'show'
				: 'hide';
		case 'remove-star-rating':
		case 'star-rating':
			return queryParams.content ? 'show' : 'hide';
		default:
			return searchSortFilterInitialState.showText;
	}
};

export const searchSortFilterAtom = atom<SearchSortFilterReducerState>(
	searchSortFilterInitialState,
);

export const searchSortFilterReducer = (
	prev: SearchSortFilterReducerState,
	action: QueryParamAction,
): SearchSortFilterReducerState => {
	switch (action.type) {
		case 'reset':
			return {
				...searchSortFilterInitialState,
				lastAction: 'reset',
			};
		case 'fetch-more': {
			return {
				queryParams: {
					...prev.queryParams,
					page:
						prev.queryParams.page === 1
							? pageAfterFirst
							: prev.queryParams.page + 1,
				},
				showText: 'show',
				lastAction: 'fetch-more',
			};
		}
		case 'search': {
			const lastAction = action.payload ? 'search' : undefined;
			const content = action.payload ? escapeRegExp(action.payload) : undefined;
			return {
				queryParams: {
					...prev.queryParams,
					content,
					page: queryParamInitialState.page,
				},
				showText: action.payload
					? 'hide-if-loading'
					: getText({ actionType: action.type, queryParams: prev.queryParams }),
				lastAction,
			};
		}
		case 'remove-star-rating':
		case 'star-rating': {
			return {
				queryParams: {
					...prev.queryParams,
					stars: action.payload,
					page: queryParamInitialState.page,
				},
				showText:
					action.payload.length === 0
						? getText({
								actionType: action.type,
								queryParams: prev.queryParams,
						  })
						: 'hide-if-loading',
				lastAction: action.type,
			};
		}

		case 'sort': {
			return {
				queryParams: {
					...prev.queryParams,
					sort: action.payload,
					page: queryParamInitialState.page,
				},
				showText: prev.queryParams.content ? 'show' : 'hide',
				lastAction: 'sort',
			};
		}
		default:
			return prev;
	}
};

export const sortSelectionAtom = selectAtom(
	searchSortFilterAtom,
	(state) => state.queryParams.sort,
);
export const searchTextAtom = selectAtom(
	searchSortFilterAtom,
	(state) => state.queryParams.content,
);
export const isViewMoreAtom = selectAtom(
	searchSortFilterAtom,
	(state) => state.queryParams.page > 1,
);

export const searchTextDisplayAtom = atom<string>(
	initialState.searchTextDisplay,
);

export const currentStarRatingsAtom = atom<string[]>([]);
export const previousStarRatingsAtom = atom<string[]>([]);
export const areStarRatingsSavedAtom = atom<boolean>(true);

export const searchSortFilterAppliedAtom = atom((get) => {
	const {
		queryParams: { content, sort, stars },
	} = get(searchSortFilterAtom);
	return !!content || sort !== queryParamInitialState.sort || !!stars;
});

export const pageAtom = selectAtom(
	searchSortFilterAtom,
	(state) => state.queryParams.page,
);
export const vendorIdAtom = atom('');

let controller: AbortController | undefined;

export const reviewsAtom = loadable(
	atom<Promise<ReviewsResponseData>>(async (get) => {
		controller?.abort();
		controller = new AbortController();
		const { signal } = controller;
		const vendorId = get(vendorIdAtom);
		const { queryParams, lastAction } = get(searchSortFilterAtom);
		if (lastAction === 'ssr-hydration') {
			return Promise.resolve({ data: [], totalCount: 0 });
		}

		return getReviewsForStorefront(vendorId, queryParams, signal).then(
			(response) => {
				if (response.ok) {
					return response.json();
				}
				throw new Error('Failed to fetch reviews');
			},
		);
	}),
);
