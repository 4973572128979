import { FilmstripCarousel } from '@components/filmstrip-carousel';
import { galleryAtom } from '@components/reviews/atoms';
import { openReviewsLightboxAtom } from '@components/reviews/components/reviews-photos-lightbox/atoms';
import { DesktopReviewPhotosLightbox } from '@components/reviews/components/reviews-photos-lightbox/desktop';
import MobileReviewPhotosLightbox from '@components/reviews/components/reviews-photos-lightbox/mobile';
import UnifiedLightboxModal from '@components/unified-lightbox/Lightbox';
import { openUnifiedLightboxAtom } from '@components/unified-lightbox/atoms';
import useTrackUnifiedLightbox from '@components/unified-lightbox/hooks/use-track-unified-lightbox';
import AnalyticsConstants from '@constants/analytics/index.js';
import { useUgcStorefrontAnalytics } from '@containers/review-photos/hooks/use-ugc-storefront-analytics';
import { isStorefrontLightboxRevampSelector } from '@redux/experiments/selectors/storefront-lightbox-revamp';
import { useAppSelector } from '@redux/hooks';
import { DisplayButton } from '@xo-union/tk-ui-links';
import { Editorial } from '@xo-union/tk-ui-typography';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useState, useCallback } from 'react';
import Styles from './filmstrip.scss';

export const ReviewPhotosFilmstrip = () => {
	const isMobile = useAppSelector((state) => state.viewport.isMobile);
	const gallery = useAtomValue(galleryAtom);
	const openReviewsLightbox = useSetAtom(openReviewsLightboxAtom);
	const trackUgcStorefrontAnalytics = useUgcStorefrontAnalytics();
	const vendor = useAppSelector(
		(state) => state.vendor.vendor as Vendor.Decorated,
	);

	const trackUnifiedLightbox = useTrackUnifiedLightbox(vendor);

	// Unified Lightbox
	const isInLightboxRevampExperiment = useAppSelector(
		isStorefrontLightboxRevampSelector,
	);
	const [isUnifiedLightboxModalOpen, setIsUnifiedLightboxModalOpen] =
		useState<boolean>(false);
	const setOpenUnifiedLightbox = useSetAtom(openUnifiedLightboxAtom);

	const handleOpenUnifiedLightbox = async (
		overview: boolean,
		index: number,
	) => {
		const reviewMediaId = !overview ? gallery[index].id : undefined;
		await setOpenUnifiedLightbox({
			overview,
			indexInAllMedia: index,
			filter: 'reviews',
			reviewMediaId,
		});
		setIsUnifiedLightboxModalOpen(true);
	};

	const onClick = useCallback(
		(index: number, modalType: 'overview' | 'details') => {
			const paidStatus = vendor.isPaid ? 'paid' : 'unpaid';
			trackUgcStorefrontAnalytics('view photo', `${paidStatus} storefront`);

			isInLightboxRevampExperiment
				? handleOpenUnifiedLightbox(
						modalType === 'overview' ? true : false,
						index,
				  )
				: openReviewsLightbox({ gallery, index, modalType });
		},
		[
			vendor.isPaid,
			gallery,
			openReviewsLightbox,
			trackUgcStorefrontAnalytics,
			isInLightboxRevampExperiment,
			handleOpenUnifiedLightbox,
		],
	);

	const onClickSeeAll = useCallback(() => {
		trackUgcStorefrontAnalytics('see all CTA', 'review gallery filmstrip');
		onClick(0, 'overview');
	}, [trackUgcStorefrontAnalytics, onClick]);

	if (!gallery?.length) {
		return null;
	}

	return (
		<div className={Styles.filmstripContainer}>
			<div className={Styles.headerContainer}>
				<Editorial bold>Review photos</Editorial>
				<div className={Styles.seeAllContainer}>
					<DisplayButton
						type="button"
						color="primary"
						onClick={() => {
							onClickSeeAll();
							if (isInLightboxRevampExperiment) {
								trackUnifiedLightbox(
									AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
									AnalyticsConstants.SEE_ALL_IMAGES,
									AnalyticsConstants.portfolioType.REVIEWS,
								);
							}
						}}
					>
						See all
					</DisplayButton>
				</div>
			</div>
			<FilmstripCarousel galleryItems={gallery} onClick={onClick} />
			{isMobile ? (
				<MobileReviewPhotosLightbox />
			) : (
				<DesktopReviewPhotosLightbox />
			)}
			{isInLightboxRevampExperiment && isUnifiedLightboxModalOpen && (
				<UnifiedLightboxModal
					entryPoint="Reviews"
					isModalOpen={isUnifiedLightboxModalOpen}
					closeCallback={() => setIsUnifiedLightboxModalOpen(false)}
				/>
			)}
		</div>
	);
};
