import Modal from '@xo-union/tk-component-modals';
import React, { useState } from 'react';
import { TeamMemberImage } from './components/team-member-image/team-member-image';
import {
	TeamMemberBio,
	TeamMemberInfo,
} from './components/team-member-info/team-member-info';
import Style from './team-member-card.scss';

type TeamMemberCardProps = {
	memberBio: TeamMemberBio;
	className?: string;
	isReduced?: boolean;
};

export const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
	memberBio,
	className = '',
	isReduced = false,
}) => {
	const [openModal, setOpenModal] = useState(false);

	const openMemberCardModal = () => {
		setOpenModal(true);
	};

	const closeModalHandler = () => {
		setOpenModal(false);
	};

	return (
		<>
			<div
				className={`${Style.container} ${
					isReduced ? Style.reduced : Style.complete
				} ${className}`}
			>
				<TeamMemberImage
					className={Style.image}
					imageUrl={memberBio.photo?.url}
				/>
				<TeamMemberInfo
					memberBio={memberBio}
					isReduced={isReduced}
					onClickReadMore={openMemberCardModal}
				/>
			</div>
			{openModal && (
				<Modal size="lg" onClose={closeModalHandler}>
					<div className={`${Style.container} ${Style.complete} ${className}`}>
						<TeamMemberImage
							className={Style.image}
							imageUrl={memberBio.photo?.url}
						/>
						<TeamMemberInfo
							className={Style.modalInfoContainer}
							memberBio={memberBio}
							showReadMore={false}
						/>
					</div>
				</Modal>
			)}
		</>
	);
};
