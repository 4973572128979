import { useEffect, useRef } from 'react';

interface UseImageScrollParams {
	index: number;
	individualReviewActiveIndex: number;
}

const useImageScroll = ({
	index,
	individualReviewActiveIndex,
}: UseImageScrollParams) => {
	const buttonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (buttonRef.current && index === individualReviewActiveIndex) {
			buttonRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
			});
		}
	}, [index, individualReviewActiveIndex]);

	return { buttonRef };
};

export { useImageScroll };
