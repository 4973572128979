import { RefObject, useLayoutEffect, useState } from 'react';

export const useIsOverflow = (
	ref: RefObject<HTMLParagraphElement>,
	content?: string,
) => {
	const [isOverflow, setIsOverflow] = useState(false);

	useLayoutEffect(() => {
		const { current } = ref;
		if (current) {
			const hasOverflow = current.offsetHeight < current.scrollHeight;
			setIsOverflow(hasOverflow);
		}
	}, [ref, content]);

	return isOverflow;
};
