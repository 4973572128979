import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { getPlatform } from '@utils/getPlatform';
import { useCallback } from 'react';
import VendorAnalytics from '../../../decorators/vendorAnalytics';

type UgcStorefrontAnalyticsAction =
	| 'see all CTA'
	| 'see all tile'
	| 'view photo'
	| 'nav left'
	| 'nav right'
	| 'nav to photo'
	| 'dismiss';

type UgcSourceContent =
	| 'review gallery filmstrip'
	| 'review overview'
	| 'review gallery'
	| 'paid storefront'
	| 'unpaid storefront';

export const useUgcStorefrontAnalytics = () => {
	const { track } = useAnalyticsContext();
	const vendor = useAppSelector(
		(state) => state.vendor.vendor as Vendor.Decorated,
	);
	const anonymousId = useAppSelector((state) => state.settings.anonymousId);

	return useCallback(
		(
			action: UgcStorefrontAnalyticsAction,
			sourceContent: UgcSourceContent,
			photoNumber?: number,
		) => {
			const paidStatus = vendor.isPaid ? 'paid' : 'unpaid';
			const vendorAnalytics = new VendorAnalytics(vendor);

			track({
				event: 'Review Interaction',
				properties: {
					...vendorAnalytics.baseEventProps(),
					action,
					anonymousId,
					platform: getPlatform(),
					sourcePage: `${paidStatus} storefront`,
					sourceContent,
					...(photoNumber !== undefined && { photoNumber }),
				},
			});
		},
		[track, vendor],
	);
};
