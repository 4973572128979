import { useScreenDetails } from '@hooks/useScreenDetails';
import { Body1, Body2 } from '@xo-union/tk-ui-typography';
import React, { FC } from 'react';
import { Review } from 'types/reviews';
import { useReviewDate } from '../../../../../../pages/Storefront/containers/reviews/components/review/hooks/useReviewDate';
import ReviewStars from '../../../../../../pages/Storefront/containers/vendor_info/components/review_stars';
import { formatReviewerName } from '../utils';
import Styles from './styles.scss';

export interface ReviewContentProps {
	review: Review | undefined;
}

const ReviewContent: FC<ReviewContentProps> = (props) => {
	const { review } = props;

	if (!review) {
		return null;
	}

	const [formattedCreatedDate] = useReviewDate(review.createdDate);
	const [isLandscape] = useScreenDetails();
	const contentStyles = isLandscape ? Styles.contentLandscape : Styles.content;

	return (
		<>
			<div className={Styles.reviewer}>
				<Body1 bold className={Styles.name}>
					{formatReviewerName(
						review.reviewer.firstName,
						review.reviewer.lastName,
					)}
				</Body1>
				<Body2
					className={Styles.date}
				>{`Reviewed on ${formattedCreatedDate}`}</Body2>
			</div>
			<div>
				<span className={Styles.starWrapper}>
					<ReviewStars overallRating={review.rating} size="md" />
				</span>
				<Body2 bold className={Styles.rating}>
					{review.rating.toPrecision(2)}
				</Body2>
			</div>
			<Body1 className={contentStyles} data-testid="review-content-test">
				{review.content}
			</Body1>
		</>
	);
};

export { ReviewContent };
