import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import Icon from '@xo-union/tk-component-icons';
import { useSetAtom } from 'jotai';
import { useReducerAtom } from 'jotai/utils';
import React, { FC } from 'react';
import VendorAnalytics from '../../../../../../decorators/vendorAnalytics';
import {
	currentStarRatingsAtom,
	previousStarRatingsAtom,
	searchSortFilterAtom,
	searchSortFilterReducer,
} from '../../atoms';
import Styles from './styles.scss';

export interface StarPillProps {
	text: string;
}

const StarPill: FC<StarPillProps> = (props) => {
	const { text } = props;
	const setCurrentStarRatings = useSetAtom(currentStarRatingsAtom);
	const setPreviousStarRatings = useSetAtom(previousStarRatingsAtom);
	const [, dispatch] = useReducerAtom(
		searchSortFilterAtom,
		searchSortFilterReducer,
	);
	const { track } = useAnalyticsContext();
	const vendor = useAppSelector((state) => state.vendor.vendor);

	const handleClose = () => {
		const vendorAnalytics = vendor
			? new VendorAnalytics(vendor).baseEventProps()
			: {};

		setCurrentStarRatings((prevState) => {
			const nextSet = prevState.slice();
			const result = nextSet.filter((t) => t !== text);
			const ratings = result.map((rating) =>
				parseInt(rating.split(' ')[0], 10),
			);
			setPreviousStarRatings(result);
			dispatch({ type: 'remove-star-rating', payload: ratings });
			return result;
		});

		track({
			event: 'Review Interaction',
			properties: {
				...vendorAnalytics,
				action: 'remove filter',
			},
		});
	};

	return (
		<div className={Styles.pill}>
			<span className={Styles.text}>{text}</span>
			<button type="button" onClick={handleClose} className={Styles.closeBtn}>
				<Icon size="sm" name="close" aria-hidden="true" />
			</button>
		</div>
	);
};

export { StarPill };
