import { usePictureWrapper } from '@components/reviews/components/reviews-photos-lightbox/hooks/use-picture-wrapper';
import { Img, Picture } from '@xo-union/tk-component-picture';
import React, { FC } from 'react';
import { useImageScroll } from '../../hooks/use-image-scroll';
import { useUpdateIndexBySourceId } from '../../hooks/use-update-index-by-source-id';
import Styles from './styles.scss';

export interface DetailsFilmstripImageProps {
	index: number;
	individualReviewActiveIndex: number;
	sourceId: string;
}

const DetailsFilmstripImage: FC<DetailsFilmstripImageProps> = (props) => {
	const { index, individualReviewActiveIndex, sourceId } = props;
	const { idToUse, imageComplete, onError, onLoad } =
		usePictureWrapper(sourceId);
	const updateIndexBySourceId = useUpdateIndexBySourceId();
	const imageStyles =
		index === individualReviewActiveIndex
			? `${Styles.image} ${Styles.activeImage}`
			: Styles.image;

	const { buttonRef } = useImageScroll({ index, individualReviewActiveIndex });

	return (
		<button
			className={Styles.button}
			type="button"
			onClick={() => updateIndexBySourceId(sourceId)}
			ref={buttonRef}
		>
			<Picture id={idToUse}>
				<Img
					width={40}
					height={40}
					className={imageStyles}
					alt={`User review media ${index + 1}`}
					elementProps={{
						onError,
						onLoad,
						ref: imageComplete,
					}}
				/>
			</Picture>
		</button>
	);
};

export { DetailsFilmstripImage };
