import { useMemo } from 'react';
import { Gallery } from 'types/reviews';

const useCalculateGalleryItemHeight = (galleryItem: Gallery, width: number) =>
	useMemo(() => {
		if ('height' in galleryItem && 'width' in galleryItem) {
			const ratio = Number(galleryItem.width) / Number(galleryItem.height);

			return width / ratio;
		}

		return 250;
	}, [galleryItem]);

export { useCalculateGalleryItemHeight };
