import { useScreenDetails } from '@hooks/useScreenDetails';
import { useAtomValue } from 'jotai';
import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import {
	isReviewsLightboxOpenAtom,
	reviewsLightboxGalleryAtom,
	reviewsLightboxModalTypeAtom,
} from '../atoms';
import { DetailsLightbox } from './details-lightbox';
import { OverviewLightbox } from './overview-lightbox';
import { ReviewsModalWrapper } from './reviews-modal-wrapper';

interface StateProps {
	vendorName: Vendor.Decorated['name'];
}

type MobileReviewPhotosLightboxProps = StateProps;

const MobileReviewPhotosLightbox: FC<MobileReviewPhotosLightboxProps> = (
	props,
) => {
	const { vendorName } = props;
	const [activeImageIndex, setActiveImageIndex] = useState(0);
	const galleryItems = useAtomValue(reviewsLightboxGalleryAtom);
	const isReviewsLightboxOpen = useAtomValue(isReviewsLightboxOpenAtom);
	const reviewsLightboxModalType = useAtomValue(reviewsLightboxModalTypeAtom);

	const [isLandscape] = useScreenDetails();

	if (!isReviewsLightboxOpen) {
		return null;
	}

	return (
		<ReviewsModalWrapper
			mediaLength={galleryItems.length}
			vendorName={`${vendorName} Review Photos`}
			sourceContent="individual review gallery"
			isLandscape={isLandscape}
		>
			{reviewsLightboxModalType === 'overview' && (
				<OverviewLightbox
					activeImageIndex={activeImageIndex}
					setActiveImageIndex={setActiveImageIndex}
				/>
			)}
			{reviewsLightboxModalType === 'details' && <DetailsLightbox />}
		</ReviewsModalWrapper>
	);
};

const mapStateToProps = (state: Redux.State): StateProps => ({
	vendorName: state.vendor.vendor?.name as Vendor.Decorated['name'],
});

export default connect<StateProps, {}, {}>(mapStateToProps)(
	MobileReviewPhotosLightbox,
);
