import { Button } from '@xo-union/tk-component-buttons';
import { useAtomValue } from 'jotai';
import React, { FC } from 'react';
import { areStarRatingsSavedAtom } from '../../../atoms';
import { useStarReviews } from '../../../hooks/use-star-reviews';
import Styles from './styles.scss';

interface FooterProps {
	handleClose?: () => void;
}

const Footer: FC<FooterProps> = (props) => {
	const { handleClose } = props;
	const areStarRatingsSaved = useAtomValue(areStarRatingsSavedAtom);
	const { handleSave } = useStarReviews(handleClose);

	return (
		<div className={Styles.ctaContainer}>
			<Button
				onClick={handleSave}
				disabled={areStarRatingsSaved}
				className={Styles.ctaButton}
				block="sm"
				color="primary"
			>
				Show reviews
			</Button>
		</div>
	);
};

export { Footer };
