import { useUgcStorefrontAnalytics } from '@containers/review-photos/hooks/use-ugc-storefront-analytics';
import { IconButton } from '@xo-union/tk-component-icons';
import classNames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
	reviewsLightboxGalleryAtom,
	reviewsLightboxIndexAtom,
	reviewsLightboxLandscapeAtom,
	reviewsLightboxNextImageAtom,
	reviewsLightboxPrevImageAtom,
} from '../../atoms';
import { useFetchGalleryReviews } from '../../hooks/use-gallery-review';
import { useReviewsLightboxKeydown } from '../../hooks/use-reviews-lightbox-keydown';
import { MediaContainer } from '../media-container';
import Styles from './styles.scss';

const NextButton = () => {
	const nextImage = useSetAtom(reviewsLightboxNextImageAtom);
	const galleryItems = useAtomValue(reviewsLightboxGalleryAtom);
	const index = useAtomValue(reviewsLightboxIndexAtom);
	const trackUgcStorefrontAnalytics = useUgcStorefrontAnalytics();

	const handleNextClick = useCallback(() => {
		nextImage();
		trackUgcStorefrontAnalytics('nav right', 'review gallery');
	}, [nextImage, trackUgcStorefrontAnalytics]);

	return (
		<div className={Styles.iconWrapper} aria-roledescription="next button">
			<IconButton<HTMLButtonElement>
				className={classNames(Styles.nextButton, {
					[Styles.hidden]: index >= galleryItems.length - 1,
				})}
				name="caret_right"
				onClick={handleNextClick}
				size="md"
				aria-label="next button"
			/>
		</div>
	);
};

const PrevButton = () => {
	const prevImage = useSetAtom(reviewsLightboxPrevImageAtom);
	const index = useAtomValue(reviewsLightboxIndexAtom);
	const trackUgcStorefrontAnalytics = useUgcStorefrontAnalytics();

	const handlePrevClick = useCallback(() => {
		prevImage();
		trackUgcStorefrontAnalytics('nav left', 'review gallery');
	}, [prevImage, trackUgcStorefrontAnalytics]);

	return (
		<div className={Styles.iconWrapper} aria-roledescription="previous button">
			<IconButton<HTMLButtonElement>
				className={classNames(Styles.previousButton, {
					[Styles.hidden]: index <= 0,
				})}
				name="caret_left"
				onClick={handlePrevClick}
				size="md"
				aria-label="previous button"
			/>
		</div>
	);
};

export const Carousel = () => {
	const galleryItems = useAtomValue(reviewsLightboxGalleryAtom);
	const index = useAtomValue(reviewsLightboxIndexAtom);
	const lightboxOrientation = useAtomValue(reviewsLightboxLandscapeAtom);
	const id = useMemo(() => uuidv4(), []);
	useFetchGalleryReviews();
	useReviewsLightboxKeydown();

	return (
		<div
			className={classNames(Styles.carouselWrapper)}
			data-testid="carousel-wrapper"
			aria-label="carousel"
			aria-roledescription="carousel"
			aria-describedby={id}
			role="group"
		>
			<span
				aria-atomic="true"
				aria-live="assertive"
				className="sr-only"
				id={id}
				style={{ display: 'none' }}
			>
				{`Showing slide number ${index + 1} out of ${galleryItems.length}`}
			</span>
			<PrevButton />
			<div
				className={classNames(
					Styles.flex,
					Styles.flexWrap,
					Styles.justifyCenter,
					Styles.landscape,
					Styles.alignContentCenter,
				)}
			>
				<div
					className={classNames({
						[Styles.landscape]: lightboxOrientation === 'landscape',
						[Styles.portrait]: lightboxOrientation === 'portrait',
					})}
					data-testid="carousel-media-wrapper"
					role="presentation"
				>
					{galleryItems.map((item, idx) => {
						const key = `${idx}_carousel_${item.id}`;
						const visible = index === idx;
						return (
							<MediaContainer
								key={key}
								galleryItem={item}
								totalCount={galleryItems.length}
								index={idx}
								visible={visible}
							/>
						);
					})}
				</div>
			</div>
			<NextButton />
		</div>
	);
};
