import { useAppSelector } from '@redux/hooks';
import { Decorated } from '@typings/vendor';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import {
	currentGalleryReviewIndexAtom,
	fetchNextGalleryReviewsForVendorAtom,
	galleryReviewsAtom,
	reviewsLightboxIndexAtom,
} from '../atoms';

export const useFetchGalleryReviews = () => {
	const index = useAtomValue(reviewsLightboxIndexAtom);
	const previousIndex = useRef<number | undefined>(undefined);
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const fetchNextPage = useSetAtom(fetchNextGalleryReviewsForVendorAtom);
	useEffect(() => {
		if (previousIndex.current === undefined || index > previousIndex.current) {
			fetchNextPage(vendor.id);
		}
		previousIndex.current = index;
	}, [fetchNextPage, index, vendor.id]);
};

export const useGalleryReview = () => {
	const currentGalleryReviewIndex = useAtomValue(currentGalleryReviewIndexAtom);
	const galleryReviews = useAtomValue(galleryReviewsAtom);
	return galleryReviews[currentGalleryReviewIndex];
};
