import { useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import {
	reviewsLightboxNextImageAtom,
	reviewsLightboxPrevImageAtom,
} from '../atoms';

const useReviewsLightboxKeydown = () => {
	const nextImage = useSetAtom(reviewsLightboxNextImageAtom);
	const prevImage = useSetAtom(reviewsLightboxPrevImageAtom);

	const keydown = useCallback(() => {
		return (event: KeyboardEvent) => {
			switch (event.key) {
				case 'ArrowLeft': {
					prevImage();
					break;
				}

				case 'ArrowRight': {
					nextImage();
					break;
				}
				default:
					break;
			}
		};
	}, [nextImage, prevImage]);

	useEffect(() => {
		const keydownEvent = keydown();
		window.addEventListener('keydown', keydownEvent);
		return () => {
			window.removeEventListener('keydown', keydownEvent);
		};
	}, [keydown]);
};

export { useReviewsLightboxKeydown };
