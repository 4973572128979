import NavigableModal, {
	MODAL_TYPES,
} from '@components/NavigableModal/NavigableModal';
import { ModalPortal } from '@xo-union/ui-accessibility';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { closeReviewsLightboxAtom, isReviewsLightboxOpenAtom } from '../atoms';
import { Body } from './body';
import { Header } from './header';
import Styles from './styles.scss';

const DesktopReviewPhotosLightbox = () => {
	const isReviewsLightboxOpen = useAtomValue(isReviewsLightboxOpenAtom);
	const closeReviewsLightBox = useSetAtom(closeReviewsLightboxAtom);

	return (
		<ModalPortal>
			{isReviewsLightboxOpen && (
				<NavigableModal
					hash="reviews-lightbox-modal"
					isModalOpen={isReviewsLightboxOpen}
					closeCallback={closeReviewsLightBox}
					type={MODAL_TYPES.CONTAINER}
				>
					<div className={Styles.container}>
						<Header />
						<Body />
					</div>
				</NavigableModal>
			)}
		</ModalPortal>
	);
};

export { DesktopReviewPhotosLightbox };
