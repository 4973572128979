import { Cta } from '@components/pricing/pricing-details/Cta';
import { DisclaimerText } from '@components/pricing/pricing-details/DisclaimerText';
import { Faq } from '@components/pricing/pricing-details/Faq';
import { Editorial } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React from 'react';
import { formattedPricingDetailsAtom } from '../../../jotai/storefront/pricing';
import Styles from './PricingDetails.scss';

export const PricingDetails = () => {
	const pricingDetails = useAtomValue(formattedPricingDetailsAtom);

	return (
		<div className={Styles.pricingDetails}>
			<Editorial bold>Starting prices</Editorial>
			<div className={Styles.faqs}>
				<Faq
					text="Reception"
					iconName="category-rec"
					value={pricingDetails.reception}
				/>
				<Faq
					text="Ceremony"
					iconName="category-cer"
					value={pricingDetails.ceremony}
				/>
				<Faq
					text="Bar services"
					iconName="category-bar"
					value={pricingDetails.barService}
				/>
				<Faq
					text="Catering"
					iconName="category-cat"
					value="Contact for price"
				/>
			</div>
			<DisclaimerText />
			<Cta />
		</div>
	);
};
