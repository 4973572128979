import { useEscape } from '@hooks/useEscape';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import { ClassesRecord, compose } from '@xo-union/react-css-modules';
import { Button, ButtonClasses } from '@xo-union/tk-component-buttons';
import { useOutsideClickEffect } from '@xo-union/ui-accessibility';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import { RatingsModal } from './ratings-modal';
import { Body } from './ratings-modal/body';
import Styles from './styles.scss';

const pillClasses = compose<ButtonClasses, ClassesRecord<ButtonClasses>>({
	'animated-element': Styles.animatedElement,
});

const SearchSortFilterPill = () => {
	const containerRef = useRef<HTMLDivElement>(null);
	const isMobile = useAppSelector((state) => selectIsMobile(state));
	const [isRatingMenuOpen, setIsRatingMenuOpen] = useState(false);

	const handleClick = () => {
		setIsRatingMenuOpen((prevState) => !prevState);
	};

	const handleClose = () => {
		setIsRatingMenuOpen(false);
	};

	useEscape(handleClose);

	useOutsideClickEffect(
		{
			onClickOutside: isMobile
				? () => {
						/* noop */
				  }
				: handleClose,
		},
		containerRef,
	);

	return (
		<div ref={containerRef} className={Styles.pillContainer}>
			<Button
				className={classnames({
					[Styles.menuOpen]: isRatingMenuOpen,
				})}
				classes={pillClasses}
				onClick={handleClick}
				color="secondary-alternative"
				size="sm"
			>
				Filter by rating
			</Button>
			{isRatingMenuOpen &&
				(isMobile ? (
					<RatingsModal
						isModalOpen={isRatingMenuOpen}
						handleClose={handleClose}
					/>
				) : (
					<Body isMobile={isMobile} handleClose={handleClose} />
				))}
		</div>
	);
};

export { SearchSortFilterPill };
