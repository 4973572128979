import { usePictureWrapper } from '@components/photo-picture-wrapper/hooks/use-picture-wrapper';
import type { PhotoPictureWrapperProps } from '@components/photo-picture-wrapper/types';
import { buildSourceProps } from '@components/photo-picture-wrapper/utils/source';
import { Img, Picture, Source } from '@xo-union/tk-component-picture';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import Styles from './styles.scss';

const determineDimension = (
	scaling: number,
	imgDimension: number | undefined,
	defaultDimension: number,
) => {
	const dimension = imgDimension ? imgDimension : defaultDimension;

	return Math.round(dimension * scaling);
};

export const PhotoPictureWrapper = ({
	id,
	src,
	smartCrop = false,
	lazy = false,
	fit = false,
	preload = false,
	containerClassName = '',
	className = '',
	width = 0,
	height = 0,
	scaling = 1.5,
	xs,
	sm,
	md,
	lg,
	xl,
	xxl,
	imgProps = {},
}: PhotoPictureWrapperProps) => {
	const { isErrored, isImageLoaded, onLoad, onError, idToUse, imageComplete } =
		usePictureWrapper(id);

	const determinedHeight = determineDimension(
		scaling,
		imgProps?.height,
		height,
	);
	const determinedWidth = determineDimension(scaling, imgProps?.width, width);

	return (
		<div
			className={classNames(containerClassName, Styles.loadingContainer, {
				[Styles.animation]: !isImageLoaded,
			})}
		>
			<Picture
				id={idToUse}
				preload={preload}
				elementProps={{
					className: classNames(Styles.picture, className),
				}}
			>
				{xs ? (
					<Source
						viewport="xs"
						{...buildSourceProps({
							source: xs,
							scaling,
							smartCrop,
							fit,
							src,
							id: idToUse,
							isErrored,
						})}
					/>
				) : (
					<Fragment />
				)}
				{sm ? (
					<Source
						viewport="sm"
						{...buildSourceProps({
							source: sm,
							scaling,
							smartCrop,
							fit,
							src,
							id: idToUse,
							isErrored,
						})}
					/>
				) : (
					<Fragment />
				)}
				{md ? (
					<Source
						viewport="md"
						{...buildSourceProps({
							source: md,
							scaling,
							smartCrop,
							fit,
							src,
							id: idToUse,
							isErrored,
						})}
					/>
				) : (
					<Fragment />
				)}
				{lg ? (
					<Source
						viewport="lg"
						{...buildSourceProps({
							source: lg,
							scaling,
							smartCrop,
							fit,
							src,
							id: idToUse,
							isErrored,
						})}
					/>
				) : (
					<Fragment />
				)}
				{xl ? (
					<Source
						viewport="xl"
						{...buildSourceProps({
							source: xl,
							scaling,
							smartCrop,
							fit,
							src,
							id: idToUse,
							isErrored,
						})}
					/>
				) : (
					<Fragment />
				)}
				{xxl ? (
					<Source
						viewport="xxl"
						{...buildSourceProps({
							source: xxl,
							scaling,
							smartCrop,
							fit,
							src,
							id: idToUse,
							isErrored,
						})}
					/>
				) : (
					<Fragment />
				)}
				<Img
					{...imgProps}
					src={idToUse || isErrored ? undefined : imgProps?.src ?? src}
					smartCrop={imgProps?.smartCrop ?? smartCrop}
					fit={imgProps?.fit ?? fit}
					className={imgProps?.className || ''}
					height={determinedHeight}
					width={determinedWidth}
					elementProps={{
						...(imgProps?.elementProps || {}),
						loading: lazy ? 'lazy' : 'eager',
						onError,
						onLoad,
						ref: imageComplete,
					}}
				/>
			</Picture>
		</div>
	);
};
