import { useEffect, useMemo } from 'react';

interface CurrentRange {
	maxRange: number;
	minRange: number;
}

interface BaseParams {
	index: number;
	currentRange: CurrentRange;
}

interface UseIsWithinRangeParams extends BaseParams {
	isVisible: boolean;
	setIsVisible: (value: boolean) => void;
}

const calculateIsVisible = ({ currentRange, index }: BaseParams) => {
	return index >= currentRange.minRange && index <= currentRange.maxRange;
};

const useIsWithinRange = ({
	currentRange,
	index,
	isVisible,
	setIsVisible,
}: UseIsWithinRangeParams) => {
	const isWithinRange = useMemo(() => {
		return isVisible || calculateIsVisible({ currentRange, index });
	}, [isVisible, index, currentRange]);

	useEffect(() => {
		if (isWithinRange) {
			setIsVisible(true);
		}
	}, [isWithinRange, setIsVisible]);
};

export { calculateIsVisible, useIsWithinRange };
