import { Body2 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import React, { memo } from 'react';
import { reviewsLightboxIndexAtom } from '../../atoms';
import Styles from './styles.scss';

interface Props {
	totalCount: number;
	index: number;
}

export const PhotoCount = memo(({ index, totalCount }: Props) => {
	const activeIndex = useAtomValue(reviewsLightboxIndexAtom);
	return (
		<Body2
			className={classNames(Styles.photoCount, {
				[Styles.hidden]: activeIndex !== index,
			})}
		>{`${index + 1}/${totalCount}`}</Body2>
	);
});
