import { compose } from '@xo-union/react-css-modules';
import classnames from 'classnames';
import { Gallery, StorefrontReviews } from 'types/reviews';
import Styles from './styles.scss';

const DEFAULT_PILL_STYLES = Styles.lightboxPill;
const DEFAULT_MEDIA_WRAPPER_STYLES = Styles.lightboxMediaWrapper;

type PossibleReviews = StorefrontReviews | undefined;

const isPhotoWide = (galleryItem: Gallery) => {
	return Number(galleryItem.width) > Number(galleryItem.height);
};

const getCarouselStyles = (isLandscape: boolean) => {
	if (!isLandscape) {
		return compose({
			slideContainer: Styles.slideContainer,
			relativeContainer: Styles.lightboxCarouselPortrait,
		});
	}

	return compose({
		relativeContainer: Styles.lightboxCarouselLandscape,
	});
};

const getPhotoStyles = (isLandscape: boolean) => {
	if (!isLandscape) {
		return {
			image: Styles.lightboxImageReviews,
			pill: classnames(DEFAULT_PILL_STYLES, Styles.lightboxPillTall),
		};
	}
	return {
		image: Styles.lightboxImageReviews,
		pill: classnames(DEFAULT_PILL_STYLES, Styles.lightboxPillLandscape),
	};
};

const getLightboxWrapperStyles = (media: Gallery) => {
	if (!isPhotoWide(media)) {
		return classnames(
			DEFAULT_MEDIA_WRAPPER_STYLES,
			Styles.lightboxMediaWrapperTall,
		);
	}

	return classnames(DEFAULT_MEDIA_WRAPPER_STYLES);
};

const getReviewsWithImages = (reviews: PossibleReviews) => {
	return reviews?.collection.filter((c) => {
		return c.gallery.length > 0;
	});
};

const getReviewDataByImageSourceId = (
	reviews: PossibleReviews,
	galleryItems: Gallery[],
	reviewsLightboxIndex: number,
) => {
	const reviewsWithImages = getReviewsWithImages(reviews);
	const review = reviewsWithImages?.find((r) => {
		return r.gallery.some(
			(g) => g.sourceId === galleryItems[reviewsLightboxIndex].sourceId,
		);
	});

	return review;
};

const formatReviewerName = (firstName: string, lastName: string) => {
	const formattedFirstName = `${firstName.charAt(0).toUpperCase()}${firstName
		.slice(1)
		.toLowerCase()}`;
	const formattedLastName = `${lastName.slice(0, 1).toUpperCase()}`;

	return `${formattedFirstName} ${formattedLastName}`;
};

export {
	formatReviewerName,
	getCarouselStyles,
	getLightboxWrapperStyles,
	getPhotoStyles,
	getReviewDataByImageSourceId,
	getReviewsWithImages,
	isPhotoWide,
};
