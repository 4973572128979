import { useScreenDetails } from '@hooks/useScreenDetails';
import React, { FC } from 'react';
import { Review } from 'types/reviews';
import { useIndividualReviewImageIndex } from '../../hooks/use-individual-review-image-index';
import { DetailsFilmstripImage } from '../details-filmstrip-image';
import Styles from './styles.scss';

export interface DetailsFilmstripProps {
	review: Review | undefined;
}

const DetailsFilmstrip: FC<DetailsFilmstripProps> = (props) => {
	const { review } = props;

	if (!review) {
		return null;
	}

	const [isLandscape] = useScreenDetails();
	const containerStyles = isLandscape
		? Styles.containerLandscape
		: Styles.container;
	const individualReviewActiveIndex = useIndividualReviewImageIndex(
		review.gallery,
	);

	return (
		<div
			className={`${Styles.containerBase} ${containerStyles}`}
			data-testid="test-details-filmstrip"
		>
			{review.gallery.map((g, index) => (
				<DetailsFilmstripImage
					key={g.sourceId}
					sourceId={g.sourceId}
					index={index}
					individualReviewActiveIndex={individualReviewActiveIndex}
				/>
			))}
		</div>
	);
};

export { DetailsFilmstrip };
