import { ReadMore } from '@components/read-more/ReadMore';
import { Body1, Editorial, Overline } from '@xo-union/tk-ui-typography';
import React, { useState } from 'react';
import * as VendorProperties from 'types/vendor/properties';
import Style from './team-member-info.scss';

export interface TeamMemberBio extends VendorProperties.Bio {
	description: string;
}

type TeamMemberInfoProps = {
	memberBio: TeamMemberBio;
	isReduced?: boolean;
	onClickReadMore?: () => void;
	showReadMore?: boolean;
	className?: string;
};

export const TeamMemberInfo: React.FC<TeamMemberInfoProps> = ({
	memberBio,
	isReduced = false,
	onClickReadMore = () => {},
	showReadMore = true,
	className = '',
}) => {
	const [expanded, setExpanded] = useState(false);
	const linkColor = isReduced ? 'primary' : 'secondary';
	const clampLines = isReduced ? 3 : 5;

	const expandHandler = () => {
		isReduced ? onClickReadMore() : setExpanded(!expanded);
	};

	return (
		<div className={className} data-testid="team-member-info">
			<Editorial bold className={Style.name}>
				{memberBio.name}
			</Editorial>
			<Overline className={Style.role}>{memberBio.displayRole}</Overline>
			{showReadMore ? (
				<ReadMore
					clamp
					clampLines={clampLines}
					expanded={expanded}
					setExpanded={expandHandler}
					linkColor={linkColor}
				>
					<Body1 className={Style.desc}>{memberBio.description}</Body1>
				</ReadMore>
			) : (
				<Body1 className={Style.desc}>{memberBio.description}</Body1>
			)}
		</div>
	);
};
