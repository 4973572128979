import React, { FC } from 'react';
import { useCloseReviewsLightbox } from '../hooks/use-close-reviews-lightbox';
import Footer from './footer';
import Header from './header';

export interface ContentWrapperProps {
	vendorName: Vendor.Decorated['name'];
}

const Portrait: FC<ContentWrapperProps> = (props) => {
	const { vendorName, children } = props;
	const closeModal = useCloseReviewsLightbox();

	return (
		<>
			<Header vendorName={vendorName} closeModal={closeModal} />
			{children}
			<Footer />
		</>
	);
};

export { Portrait };
