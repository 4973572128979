import React, { useState } from 'react';
import { Carousel } from '../carousel';
import { Overview } from '../overview';
import { Content } from './Content';
import { ExpandedContent } from './ExpandedContent';
import Styles from './styles.scss';

export const Body = () => {
	const [toggle, setToggle] = useState(false);
	const onClose = () => setToggle(false);
	const onOpen = () => setToggle(true);

	return (
		<div className={Styles.body}>
			<div className={Styles.overview}>
				<Overview />
			</div>
			<div className={Styles.reviewContainer}>
				<Carousel />
				<div className={Styles.reviewContent}>
					<Content handleClick={onOpen} />
				</div>
				{toggle && (
					<div className={Styles.reviewContentReadMore}>
						<ExpandedContent handleClick={onClose} />
					</div>
				)}
			</div>
		</div>
	);
};
