import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { Gallery } from 'types/reviews';
import { reviewsLightboxGalleryAtom, reviewsLightboxIndexAtom } from '../atoms';

const useIndividualReviewImageIndex = (individualReviewGallery: Gallery[]) => {
	const allGalleryItems = useAtomValue(reviewsLightboxGalleryAtom);
	const reviewsLightboxIndex = useAtomValue(reviewsLightboxIndexAtom);

	const activeIndex = useMemo(() => {
		const currentSourceId = allGalleryItems[reviewsLightboxIndex].sourceId;
		return individualReviewGallery.findIndex(
			(g) => g.sourceId === currentSourceId,
		);
	}, [allGalleryItems, reviewsLightboxIndex, individualReviewGallery]);

	return activeIndex;
};

export { useIndividualReviewImageIndex };
