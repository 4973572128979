import React from 'react';
import InlineRfqModal from '../../../pages/Storefront/containers/InlineRFQ/components/InlineRfqModal/InlineRfqModal';
import { useInlineRfqForm } from '../../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import RFQButton from '../../../pages/Storefront/containers/cta_box/RfqButton';
import Styles from './Cta.scss';

export const Cta = () => {
	const formContext = useInlineRfqForm({
		initiator: 'Get a Personalized Quote CTA',
		freeTextLabel: 'Ask about pricing',
		headerText: 'Get a personalized quote',
		additionalInitiatedTrackingProps: {
			selection: 'View Packages',
		},
	});

	return (
		<div>
			<InlineRfqModal context={formContext} />
			<RFQButton
				wrapStyle={Styles.cta}
				type="message"
				color="secondary"
				size="md"
				label="Get a personalized quote"
				handleCTAClick={formContext.openModal}
			/>
		</div>
	);
};
