import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useBaseStorefrontAnalytics } from '@hooks/useBaseStorefrontAnalytics';
import { useAppSelector } from '@redux/hooks';
import { ClassesRecord, compose } from '@xo-union/react-css-modules';
import {
	Option,
	Select,
	SelectChangeEvent,
	SelectClasses,
} from '@xo-union/tk-component-fields';
import { DisplayButton } from '@xo-union/tk-ui-links';
import { Body1 } from '@xo-union/tk-ui-typography';
import cx from 'classnames';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import {
	currentStarRatingsAtom,
	previousStarRatingsAtom,
	searchTextAtom,
	sortSelectionAtom,
	vendorIdAtom,
} from './atoms';
import { useSearchSortFilterReviews } from './hooks/use-search-sort-filter-reviews';
import { SearchBar } from './search-bar';
import { SearchSortFilterPill } from './search-sort-filter-pill';
import { StarPills } from './star-pills';
import Styles from './styles.scss';

const selectClasses = compose<SelectClasses, ClassesRecord<SelectClasses>>({
	list: Styles.selectList,
});

export const SearchSortFilter = () => {
	const [sortOpen, setSortOpen] = useState(false);
	const sortSelection = useAtomValue(sortSelectionAtom);
	const vendorId = useAppSelector((state) => state.vendor.vendor?.id);
	const setVendorId = useSetAtom(vendorIdAtom);
	const [currentStarRating, setCurrentStarRatings] = useAtom(
		currentStarRatingsAtom,
	);
	const setPreviousStarRatings = useSetAtom(previousStarRatingsAtom);
	const searchText = useAtomValue(searchTextAtom);

	const {
		clearSearchText,
		clearAll,
		setSearchText,
		setSortSelection,
		reviewCount,
		shouldShowText,
		displayText,
	} = useSearchSortFilterReviews();

	const handleSortChange = (val: SelectChangeEvent) => {
		const newSortSelection = val.option.value;
		if (newSortSelection !== sortSelection) {
			setSortSelection(val.option.value);
		}
	};

	const handleClearAll = () => {
		clearAll();
		setCurrentStarRatings([]);
		setPreviousStarRatings([]);
	};

	useEffect(() => {
		setVendorId(vendorId || '');
	}, [vendorId, setVendorId]);

	const baseStorefrontAnalytics = useBaseStorefrontAnalytics();
	const { track } = useAnalyticsContext();

	const trackSortOpen = () =>
		track({
			event: 'Review Interaction',
			properties: {
				action: 'open sort',
				...baseStorefrontAnalytics,
			},
		});

	const trackClearAll = () =>
		track({
			event: 'Review Interaction',
			properties: {
				action: 'clear all',
				...baseStorefrontAnalytics,
			},
		});

	const handleClear = () => {
		clearSearchText();
		trackClearAll();
	};

	const handleOpen = () => {
		setSortOpen(true);
		trackSortOpen();
	};

	const handleClose = () => {
		setSortOpen(false);
	};

	const reviewCountText = useMemo(() => {
		const pluralization = reviewCount !== 1 ? 's' : '';
		const forSearchTerm =
			!currentStarRating?.length && searchText?.length
				? ` for '${searchText}'`
				: '';
		return `${reviewCount} matching review${pluralization}${forSearchTerm} | `;
	}, [reviewCount, searchText, currentStarRating]);

	return (
		<div>
			<div className={Styles.searchSortContainer}>
				<div className={Styles.searchBarItem}>
					<SearchBar handleClear={handleClear} setSearchText={setSearchText} />
				</div>
				<div className={Styles.sortDropdownItem}>
					<Select
						name="Sort by"
						classes={selectClasses}
						isOpen={sortOpen}
						value={sortSelection}
						onChange={handleSortChange}
						onOpen={handleOpen}
						onClose={handleClose}
					>
						<Option value="selectiontype:asc|createddate:desc">
							Top reviews
						</Option>
						<Option value="createddate:desc">Newest first</Option>
						<Option value="createddate:asc">Oldest first</Option>
						<Option value="rating:desc">Highest rated</Option>
						<Option value="rating:asc">Lowest rated</Option>
					</Select>
				</div>
			</div>
			<div className={Styles.searchSortPillWrapper}>
				<SearchSortFilterPill />
				<StarPills />
			</div>
			{shouldShowText && (
				<div
					className={cx(Styles.reviewsCountContainer, {
						[Styles.reviewsCountContainerResults]: reviewCount > 0,
					})}
				>
					<div>
						<Body1 className={Styles.reviewsCountText} bold>
							{reviewCountText}
						</Body1>
						<DisplayButton
							type="button"
							color="primary"
							onClick={handleClearAll}
						>
							Clear search
						</DisplayButton>
					</div>
					{displayText && (
						<p className={Styles.reviewsCountText}>{displayText}</p>
					)}
				</div>
			)}
		</div>
	);
};
