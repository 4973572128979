import noop from '@utils/noop';
import { DisplayButton } from '@xo-union/tk-ui-links';
import classNames from 'classnames';
import React, {
	type FC,
	type ReactNode,
	useCallback,
	type SetStateAction,
	type Dispatch,
	useState,
	useMemo,
	useEffect,
} from 'react';
import type { Color } from 'types/union/common';
import Styles from './styles.scss';

export interface ReadMoreProps {
	children: ReactNode;
	clamp?: boolean;
	clampDefaultShowLink?: boolean;
	clampLines?: number;
	classes?: {
		container?: string;
		content?: string;
		link?: string;
	};
	expanded: boolean;
	linkColor?: Color;
	linkText?: {
		less: string;
		more: string;
	};
	onToggle?: () => void;
	setExpanded: Dispatch<SetStateAction<boolean>>;
	showReadMore?: boolean;
}

/**
 * A component that displays a limited amount of content and allows the user to expand it.
 * If you need to show a number of items instead of clamping lines, you will need to manage the expanded UI.
 *
 * @param children - The text or content to be displayed
 * @param clamp - Enables line clamping - default is false
 * @param clampDefaultShowLink - Shows/hides link before height calc - default is false
 * @param clampLines - Number of lines to clamp - only used if clamp is true - default is 3
 * @param classes - Custom classes for the container, content, and link
 * @param expanded - Whether the content is expanded or not
 * @param linkColor - The color of the link - default is primary
 * @param linkText - The text for the link - default is 'Read more' and 'Read less'
 * @param onToggle - Callback for when the link is clicked
 * @param setExpanded - Setter for the expanded state
 * @param showReadMore - Whether to show the read more link or not - Needs controlled if not using clamping - default is false
 */
export const ReadMore: FC<ReadMoreProps> = ({
	children,
	clamp = false,
	clampDefaultShowLink = false,
	clampLines = 3,
	classes = {},
	expanded,
	linkColor = 'primary',
	linkText = { less: 'Read less', more: 'Read more' },
	onToggle = noop,
	setExpanded,
	showReadMore = false,
}) => {
	const { container = '', content = '', link = '' } = classes;
	const clampStyle = { '--clamp-lines': `${clampLines}` };
	const [lines, setLines] = useState(0);
	const [showLink, setShowLink] = useState(clampDefaultShowLink);

	const onClick = useCallback(() => {
		setExpanded((value) => !value);
		onToggle();
	}, [onToggle, setExpanded]);

	useEffect(() => {
		setShowLink((clamp && lines > clampLines) || showReadMore);
	}, [clamp, lines, clampLines, showReadMore]);

	const clampRef = useCallback(
		(el: HTMLDivElement | null) => {
			if (clamp && el) {
				const divHeight = el.scrollHeight;
				const lineHeight = parseInt(window.getComputedStyle(el).lineHeight);
				setLines(divHeight / lineHeight);
			}
		},
		[clamp],
	);

	return (
		<div
			style={clampStyle}
			className={classNames(Styles.readMoreContainer, container)}
			data-testid="read-more-container"
		>
			<div
				ref={clampRef}
				className={classNames(
					{
						[Styles.readMoreClamp]: !expanded && clamp,
					},
					content,
				)}
				data-testid="read-more-content"
			>
				{children}
			</div>
			{showLink && (
				<div>
					<DisplayButton
						type="button"
						color={linkColor}
						onClick={onClick}
						className={classNames(link)}
					>
						{expanded ? linkText.less : linkText.more}
					</DisplayButton>
				</div>
			)}
		</div>
	);
};
